import React from "react";
import KofiImage from "../images/kofi_blue.png";
import "../styles/KoFi.css";

export default function KoFi(props) {
  const { color, id, label } = props;
  return (
    <div class="btn-container">
      <a
        title={label}
        class="kofi-button"
        style={{ backgroundColor: color, paddingRight: "30px" }}
        href={"https://ko-fi.com/" + id}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span class="kofitext">
          <img src={KofiImage} class="navbutton" alt="Ko-Fi button" />
          {label}
        </span>
      </a>
    </div>

    // <div class="btn-container">
    //   <a
    //     title={label}
    //     class="kofi-button"
    //     style={{ backgroundColor: color }}
    //     href={"https://ko-fi.com/" + id}
    //     target="_blank"
    //     rel="noopener noreferrer"
    //   >
    //     <span class="kofitext">
    //       <img src={KofiImage} class="kofiimg" alt="Ko-Fi button" />
    //       {label}
    //     </span>
    //   </a>
    // </div>
  );
}
