import React from "react";
import { NavLink } from "react-router-dom";
import KofiSmaller from "./KofiSmaller";
import TwitterSmaller from "./TwitterSmaller";

export default function NavSmaller() {
  return (
    <ul>
      <div className="left">
        <li>
          <NavLink to="/" className="nav-link" style={{ fontStyle: "italic" }}>
            Calmr
          </NavLink>
        </li>
      </div>

      <div
        className="right"
        style={{ fontFamily: "Quicksand", fontWeight: "600" }}
      >
        <TwitterSmaller style={{ width: "20px" }} color="#fff" label="" />

        <KofiSmaller color="#fff" id="K3K32DO2D" label="" />
      </div>
    </ul>
  );
}
