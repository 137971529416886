import React from "react";
import { NavLink } from "react-router-dom";
import KoFi from "./KoFi";
import Twitter from "./Twitter";

export default function Nav() {
  return (
    <ul>
      <div className="left">
        <li>
          {/* <NavLink to="/about" className="nav-link">
            About
          </NavLink> */}
          <NavLink to="/" className="nav-link" style={{ fontStyle: "italic" }}>
            Calmr
          </NavLink>
        </li>
      </div>

      {/* <div className="center">
        <body>Sereno</body>
      </div> */}

      <div
        className="right"
        style={{ fontFamily: "Quicksand", fontWeight: "600" }}
      >
        <Twitter color="#fff" label="Follow @ilanapkern on Twitter" />

        <KoFi color="#fff" id="K3K32DO2D" label="Buy Me a Coffee" />
      </div>
    </ul>
  );
}
