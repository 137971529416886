import React from "react";
import "../styles/KoFi.css";
import TwitterImageBlue from "../images/Twitter_Logo_Blue.png";

export default function Twitter(props) {
  const { color, label } = props;
  return (
    <div class="btn-container" style={{ paddingRight: "10px" }}>
      <a
        title={label}
        class="kofi-button"
        style={{ backgroundColor: color }}
        href={"https://twitter.com/ilanapkern"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span class="kofitext">
          <img src={TwitterImageBlue} class="twitterimg" alt="Twitter logo" />
          {label}
        </span>
      </a>
    </div>
  );
}
