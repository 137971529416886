import React from "react";
import Music from "./Music";
import rain_img from "../images/rain_img.png";
import ocean_img from "../images/ocean_img.png";

import light_rain from "../sounds/light_rain_audio.mp4";

import thunder_img from "../images/thunder_img.png";
import forest_img from "../images/forest_img.png";

import forest_audio from "../sounds/forest_audio.mp4";

import coffeeshop_img from "../images/coffeeshop_img.png";

import coffeeshop_audio from "../sounds/coffeeshop_audio.mp4";

import thunderstorm_audio from "../sounds/thunderstorm_audio.mp4";

import ambient_ocean_audio from "../sounds/ambient_ocean_audio.mp4";

import jazz_img from "../images/jazz_img.png";

import jazz_audio from "../sounds/jazz_audio.mp4";

import flowingwater_audio from "../sounds/flowingwater_audio.mp4";

import flowingwater_img from "../images/flowingwater_img.png";

import fireplace_audio from "../sounds/fireplace_audio.mp4";

import fireplace_img from "../images/fireplace_img.png";

class Sounds extends React.Component {
  render() {
    return (
      <div>
        <div className="grid">
          <Music src={light_rain} icon={rain_img} />

          <Music src={thunderstorm_audio} icon={thunder_img} />

          <Music src={ambient_ocean_audio} icon={ocean_img} />

          <Music src={forest_audio} icon={forest_img} />

          <Music src={coffeeshop_audio} icon={coffeeshop_img} />

          <Music src={jazz_audio} icon={jazz_img} />

          <Music src={flowingwater_audio} icon={flowingwater_img} />

          <Music src={fireplace_audio} icon={fireplace_img} />
        </div>
      </div>
    );
  }
}

export default Sounds;
