import React from "react";
// import Slider from "./Slider"
import Slider from "@material-ui/core/Slider";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

/* To override the theme colors for the slider!!!*/
const muiTheme = createMuiTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "white",
        height: 24,
        width: 24,
        marginTop: -8,
        marginLeft: -12,
      },
      track: {
        color: "white",
        height: 8,
        borderRadius: 4,
        // width: 200,
      },
      rail: {
        height: 8,
        // width: 200,
        borderRadius: 4,
        color: "white",
      },
    },
  },
});

class Music extends React.Component {
  constructor(props) {
    super(props);
    this.state = { play: false };
    this.url = props.src;
    this.audio = new Audio(this.url);
    this.audio.preload = "none";
    this.audio.volume = 0.25;
    this.togglePlay = this.togglePlay.bind(this);
  }

  /* This allows us to loop through after the audio is finished playing */
  togglePlay() {
    this.setState({ play: !this.state.play }, () => {
      this.state.play ? this.audio.play() : this.audio.pause();
    });
    this.audio.loop = true;
  }

  volChange = (event, newValue) => {
    return newValue;
  };

  componentWillUnmount() {
    this.audio.pause();
  }

  handleChange = (event, value) => {
    this.value = value;
    this.audio.volume = value / 100;
  };

  render() {
    return (
      <div style={this.props.style} className="card">
        {this.state.play ? (
          <div>
            <button
              style={{ outline: "none", paddingLeft: "15%" }}
              className="btn light-btn"
              aria-label="Pause"
              onClick={this.togglePlay}
            >
              <img
                src={this.props.icon}
                width="120"
                height="120"
                alt="Pause"
              ></img>
            </button>
            {/* <Slider value={this.audio.volume} handleChange={(e, newVol) => this.audio.volume = newVol/100}/> */}
            {/* <div className={useStyles().horizontal}> */}
            <ThemeProvider theme={muiTheme}>
              <div style={{ paddingRight: "10%" }}>
                <Slider
                  defaultValue={20}
                  onChange={this.handleChange}
                  aria-labelledby="continuous-slider"
                  color="#primary"
                />
              </div>
            </ThemeProvider>
            {/* </div> */}
          </div>
        ) : (
          <button
            style={{ outline: "none", paddingLeft: "15%" }}
            className="btn light-btn-pause"
            aria-label="Play"
            onClick={this.togglePlay}
          >
            <img
              src={this.props.icon}
              width="120"
              height="120"
              alt="Play"
            ></img>
          </button>
        )}
      </div>
    );
  }
}

export default Music;
