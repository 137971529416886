import React, { Fragment } from "react";
import "./App.css";
import Nav from "./components/Nav";
import { BrowserRouter as Router } from "react-router-dom";
import Sounds from "./components/Sounds";
import ClearBtn from "./images/clearbtn.png";
import Media from "react-media";
import NavSmaller from "./components/NavSmaller";

function Todo({ todo, index, completeTodo, removeTodo }) {
  return (
    <div
      className="todo"
      style={{
        width: "90%",
        textAlign: "left",
        textDecoration: todo.isCompleted ? "line-through" : "",
      }}
    >
      {todo.text}
      <div style={{ width: "10%", textAlign: "right" }}>
        <button
          className="btn-clear todo-btn"
          style={{ outline: "none" }}
          onClick={() => removeTodo(index)}
        >
          <img src={ClearBtn} width="15" height="15" alt="Clear" />
        </button>
      </div>
    </div>
  );
}

function TodoForm({ addTodo }) {
  const [value, setValue] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!value) return;
    addTodo(value);
    setValue("");
  };

  return (
    <div className="field">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Add a new task!"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </form>
    </div>
  );
}

function App() {
  const [todos, setTodos] = React.useState([
    // {
    //   text: "Learn about React",
    //   isCompleted: false
    // },
    // {
    //   text: "Meet friend for lunch",
    //   isCompleted: false
    // },
    // {
    //   text: "Build really cool todo app",
    //   isCompleted: false
    // }
  ]);

  const addTodo = (text) => {
    const newTodos = [...todos, { text }];
    setTodos(newTodos);
  };

  const completeTodo = (index) => {
    const newTodos = [...todos];
    newTodos[index].isCompleted = true;
    setTodos(newTodos);
  };

  const removeTodo = (index) => {
    const newTodos = [...todos];
    newTodos.splice(index, 1);
    setTodos(newTodos);
  };

  return (
    <Router>
      <section className="container">
        <div className="taskbar">
          <div
            style={{
              paddingLeft: "9%",
              paddingRight: "9%",
              paddingTop: "10%",
            }}
            className="todo-list"
          >
            {todos.map((todo, index) => (
              <Todo
                key={index}
                index={index}
                todo={todo}
                completeTodo={completeTodo}
                removeTodo={removeTodo}
              />
            ))}

            <TodoForm addTodo={addTodo} />
          </div>
        </div>

        <div
          style={{
            height: "100%",
            textAlign: "center",
            paddingLeft: "30%",
          }}
        >
          <div
            style={{
              paddingTop: "2%",
              paddingLeft: "5%",
              paddingRight: "5%",
              paddingBottom: "3%",
            }}
          >
            {/* {window.innerWidth > 500 ? <Nav /> : <div>You suck</div>} */}

            {/* <Media query="(max-width: 1200px)" render={() => <Nav />} /> */}

            <Media
              queries={{
                small: "(max-width: 599px)",
                medium: "(min-width: 600px) and (max-width: 1199px)",
                large: "(min-width: 1200px)",
              }}
            >
              {(matches) => (
                <Fragment>
                  {matches.small && <NavSmaller />}
                  {matches.medium && <NavSmaller />}
                  {matches.large && <Nav />}
                </Fragment>
              )}
            </Media>
          </div>
          <body
            style={{
              paddingTop: "70px",
              paddingBottom: "40px",
              fontStyle: "italic",
            }}
          >
            What would you like to listen to in the background?
          </body>

          <div style={{ paddingLeft: "7%" }}>
            <Sounds />
          </div>

          <div style={{ height: "65px" }} />

          <body className="link-text">
            Icons made by{" "}
            <a
              className="link"
              href="https://www.flaticon.com/authors/dinosoftlabs"
              title="DinosoftLabs"
              target="_blank"
              rel="noopener noreferrer"
            >
              DinosoftLabs
            </a>{" "}
            from{" "}
            <a
              className="link"
              href="https://www.flaticon.com/"
              title="Flaticon"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.flaticon.com
            </a>
          </body>
          <div style={{ height: "65px" }} />
        </div>
      </section>
    </Router>
  );
}

export default App;
